"use client"
import LearnMore from "@/components/common/learn-more-button";
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
import Image from "next/image";
import { useEffect } from "react";

const WhoWeare = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const AOS = require('aos');
            AOS.init();
        }
    }, []);
    return (
        <section className="pt-7 lg:pt-14 md:pt-14 overflow-hidden">
            <div className="container">
                <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-3">
                    <div data-aos="fade-up" data-aos-duration="1000">
                        <div className="">
                            <p className="text-[#A24DC0] font-normal text-xl pb-0">Who we are</p>
                            <h2 className="text-gray font-semibold text-[20px] lg:text-[32px] md:text-3xl leading-tight">Experienced SAP professionals providing traning and scaling services</h2>
                            <p className="text-gray font-normal py-3 lg:py-5 pe-0 lg:pe-[38px] md:pe-0 text-[1rem] lg:text-lg md:text-base">ExC Academy seamlessly integrates traditional offline learning, skill development, comprehensive training, and physical classrooms, offering a holistic educational experience all under one roof.</p>
                            <LearnMore className="uppercase hover:bg-[#121212] hover:text-[#FFFFFF] text-[#121212] font-semibold text-[0.9rem] lg:text-lg md:text-base px-3 sm:px-7 py-3 sm:py-4 border-solid border-2 border-[#121212] lg:mt-6 md:mt-4 my-5 inline-block no-arrow" />
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="1000">
                        <div className="h-[auto]">
                            <div className="w-full relative">
                                <Image src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/who-we-are.webp"
                                    className="my-auto object-cover md:h-[405px] lg:h-[initial]" alt="who we are 2nd img"
                                    height={444} width={690}
                                    blurDataURL="URL"
                                    placeholder="blur"
                                    fetchPriority="high"
                                    loading="eager"
                                    rel="preload"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WhoWeare;