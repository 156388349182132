"use client"
import { useEffect } from 'react';
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
import Image from 'next/image';

const KeyDate = [
    {
        id: 0,
        title: 'Classroom Learning Program',
        content: "Step into the immersive world of learning with ExC Academy. Our dynamic and interactive classrooms foster an environment where knowledge meets innovation. From expert-led sessions to collaborative learning experiences, we empower students with cutting-edge resources and personalized guidance.",
        img: 'https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/key_img_1.webp',
    },
    {
        id: 1,
        title: 'Career Assistance',
        content: 'At ExC Academy, we stand by our commitment to your success. Our rigorous training and personalized career support, empower you to embark on your professional journey with confidence and assurance. Join us and step into a world of SAP opportunities!',
        img: 'https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/key_img_2.webp',
    }
]

const KeyOffering = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const AOS = require('aos');
            AOS.init();
        }
    }, []);
    return (
        <section className="key-offering py-7 lg:py-14 md:py-14 bg-[#F5F5F5]">
            <div className="container" data-aos="fade-up" data-aos-duration="1000">
                <div className="grid grid-cols-1">
                    <div>
                        <h2 className="text-center text-gray font-semibold lg:text-4xl md:text-2xl text-[20px] leading-tight">Explore Our Features</h2>
                        <p className="text-center text-gray font-normal py-5 text-[1rem] lg:text-lg md:text-base">
                            Explore the depth of ExC Academy's feature-rich world, where innovation meets seamless functionality. Our meticulously crafted Curriculum, from cutting-edge technology to user-centric experiences, redefines learning, equipping you with every tool for guaranteed success.
                        </p>
                    </div>
                    <div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-2 gap-x-20 sm:gap-x-7 md:gap-x-5 mt-7 lg:gap-y-0 md:gap-y-7 gap-y-5">
                            {KeyDate.map((i) => (
                                <div key={i.id} className={`bg-[url('https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/key-bg.webp')] overflow-hidden rounded-[10px] shadow-md 
                                lg:h-[375px] md:h-[220px] sm:h-[670px] h-[670px]
                                relative
                                block
                                bg-cover
                                bg-no-repeat
                                bg-center
                                before:content-['']
                                before:absolute
                                before:inset-0
                                before:block
                                before:bg-gradient-to-l
                                before:from-transparent
                                before:to-[#302A33]
                                before:opacity-75
                                before:z-[-5]`}
                                >
                                    <div className="block lg:flex md:flex lg:h-auto md:h-auto h-auto">
                                        <div className="w-[100%] lg:w-[60%] md:w-[80%] py-7 px-5 sm:h-auto">
                                            <h3 className="text-[20px] lg:text-[22px] md:text-[22px] text-white font-semibold pb-5 leading-tight">{i.title}</h3>
                                            <p className="text-white font-normal lg:text-base md:text-sm text-[1rem]">
                                                {i.content}
                                            </p>
                                        </div>
                                        <div className='lg:w-[40%] md:w-[20%]'>
                                            <Image src={i.img} alt="key-offerings-img" width={235} height={375} className='w-full lg:h-[375px] md:h-[280px] sm:h-[480px] h-[480px] object-cover object-top' />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default KeyOffering;