"use client"
import StarRating from '@/components/course-details/star-rating';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import Link from '@/components/ui/link';
import Image from "next/image";
import { useRouter } from 'next/navigation';
import { HiChevronRight } from "react-icons/hi";
import { LuDot, } from "react-icons/lu";

const Ourcourse = [
    {
        id: 0,
        img_Url: 'https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/sap-ariba.webp',
        title: 'SAP Ariba',
        p_content: 'SAP Ariba is a cloud-based suite of procurement applications that helps businesses streamline their buying and selling processes. It includes modules for everything from requisitioning and purchasing to supplier management and invoice processing.',
        months: '6 months',
        location: 'Bangalore',
        all_level: 'All Levels',
        link_url: '',
    }
]

const HorizontalCard = () => {
    const { push } = useRouter();

    return (
        <div className="grid grid-cols-1">
            <div>
                {Ourcourse.map((i) => (
                    <Card key={i.id} onClick={() => push("/our-courses")} className="w-[full] rounded border-none shadow-[0_0px_15px_0px_#00000026] cursor-pointer">
                        <div className="flex sm:flex-nowrap flex-wrap">
                            <CardHeader className='p-3'>
                                <Image height={145} width={256} src={i.img_Url} alt="exa-acadamy-img"
                                    className="lg:max-w-[246px] md:max-w-[200px] sm:max-w-[200px] w-[487px] sm:h-[100%] h-[150px] object-cover rounded"
                                    blurDataURL="URL"
                                    placeholder="blur"
                                    fetchPriority="high"
                                    loading="eager"
                                    rel="preload"
                                />
                            </CardHeader>
                            <CardContent className='p-3 w-full'>
                                <div className="w-full relative">
                                    <div className="flex items-center">
                                        <h3 className="font-bold text-[20px] lg:text-2xl md:text-xl text-[#1E1E1F]">{i.title}</h3>
                                        <div className="rounded-full px-5 py-2 bg-[#BBEACF] text-center ml-5">
                                            <p className="text-[#007833] text-sm font-semibold">Popular</p>
                                        </div>
                                    </div>
                                    <div className="py-2">
                                        <StarRating />
                                    </div>
                                    <p className="line-clamp-3 text-[1rem] lg:text-[15px] md:text-sm text-[#292929] mb-3">{i.p_content}</p>
                                    <ul className="flex list-none text-[13px] lg:text-sm md:text-[10px] text-[#1E1E1F] font-normal">
                                        <li>{i.months}</li>
                                        <li className="px-2 md:pe-0 flex items-center"><LuDot /></li>
                                        <li className="flex items-center">{i.location}</li>
                                        <li className="px-2 md:pe-0 flex items-center"><LuDot /></li>
                                        <li className="flex items-center">{i.all_level}</li>
                                    </ul>
                                </div>
                                <div className="inline-block items-center">
                                    <Link href="/course-details/sap-ariba-certification-training" variant="secondary"
                                        className="hover:text-sky-700 text-learn flex items-center text-sm lg:text-base md:text-sm text-[#0074FD] mt-3"
                                    >Discover More <HiChevronRight className="text-[#0074FD]" /></Link>
                                </div>
                            </CardContent>
                        </div>
                    </Card>
                ))}
            </div>
        </div>
    );
}

export default HorizontalCard;