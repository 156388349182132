"use client"
import { useEffect } from "react";
import ExploreCourses from "@/components/common/explore-courses-button";
import Image from 'next/image';
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';

const Banner = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const AOS = require('aos');
            AOS.init();
        }
    }, []);
    return (
        <section className="overflow-hidden w-full flex items-center py-7 lg:py-0 relative h-auto lg:h-[510px] md:h-auto">
            <Image src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/hero-background.webp"
                className="absolute w-full h-full lg:h-[510px] md:h-full bg-cover bg-center" width={605} height={272}
                alt="Home Background Image"
                blurDataURL="URL"
                placeholder="blur"
                fetchPriority="high"
                loading="eager"
                rel="preload"
            />
            <div className="container">
                <div className="w-full flex-wrap sm-flex-nowrap lg:flex md:flex flex  justify-between">
                    <div className="w-full lg:w-[91%] md:w-[100%] sm:w-full order-last md:order-first z-10">
                        <div data-aos="fade-up" data-aos-duration="1000">
                            <h1 className="text-xl md:text-3xl xl:text-5xl lg:text-4xl lg:leading-tight md-mb-16 mb-6 md-mt-0  mt-6">
                                <span className="font-semibold">Learn from experts and <br className="hidden md:block"/>elevate your career </span> <br className="hidden md:block"/>
                                with Engaging <br className="hidden md:block" /> <span className="font-semibold">Classroom Experience!</span>
                            </h1>
                            <div className="max-w-full grid grid-cols-12 md:grid-cols-12 lg:grid-cols-12 gap-2 items-end justify-between">
                                <div className="lg:col-span-5 md:col-span-5 col-span-12">
                                    <ExploreCourses />
                                </div>
                                <div className="lg:col-span-7 md:col-span-7 col-span-12 bg-white rounded bg-opacity-50 px-5 py-3 flex items-center z-30 lg:w-80 md:w-64 w-[16rm]">
                                    <div className="me-4">
                                        <Image src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/certi_icon.webp"
                                            className="rounded-[7px]" width={50} height={50}
                                            alt="smallicon"
                                            blurDataURL="URL"
                                            placeholder="blur"
                                            fetchPriority="high"
                                            loading="eager"
                                            rel="preload"
                                        />
                                    </div>
                                    <div className="">
                                        <Image src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/sap_icon.svg"
                                            className="mb-2" width={56} height={28}
                                            alt="Sap Icon"
                                            blurDataURL="URL"
                                            placeholder="blur"
                                            fetchPriority="high"
                                            loading="eager"
                                            rel="preload"
                                        />
                                        <p className="text-[14px] md:text-sm lg:text-lg text-[#222222] text-normal m-0">Aligned Certification</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center relative order-first md:order-last">
                        <div data-aos="fade-up" data-aos-duration="1000" className=" relative lg:absolute md:absolute my-auto right-[0px] sm:right-0 md:right-0 lg:right-0">
                            <Image height={553} width={487}
                                className="object-contain h-[270px] xl:h-[450px] lg:h-auto md:h-[270px] max-w-auto xl:max-w-[600px]  lg:max-w-[450px] md:max-w-[350px]"
                                src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/banner.webp"
                                alt="home banner"
                                blurDataURL="URL"
                                placeholder="blur"
                                priority={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner;