"use client"
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
import Image from "next/image";
import { FaRegLightbulb } from "react-icons/fa";
import { MdAssignmentAdd } from "react-icons/md";
import { PiNotebookFill } from "react-icons/pi";
import { useEffect } from 'react';

const Assessments = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const AOS = require('aos');
            AOS.init();
        }
    }, []);
    return (
        <section className="bg-[#FAFAFA] pb-7 sm:pb-7 xl:pb-14 lg:pb-14 md:pb-14">
            <div className="container">
                <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-[20px] lg:gap-[50px] md:gap-[30px]">
                    <div data-aos="fade-up" data-aos-duration="1000">
                        <div className="overflow-hidden rounded-tl-[30px] rounded-br-[40px] h-auto m-auto">
                            <Image height={500} width={500} src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/hands-on-learning.webp"
                                className="md:h-[265px] lg:h-[initial] object-cover" alt="Assignment Image"
                                blurDataURL="URL"
                                placeholder="blur"
                                fetchPriority="high"
                                loading="eager"
                                rel="preload"
                            />
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="1000">
                        <div className="">
                            <h2 className="text-gray text-xl lg:text-[32px] md:text-2xl font-semibold lg:mb-7 mb-3 leading-tight">Hands-on-Learning</h2>
                            <p className="text-gray font-normal text-[1rem] lg:text-lg md:text-base lg:mb-9 mb-4">Easily launch live assignments, quizzes, and tests.
                                Student have real-time experience of their skills and relevant work.</p>
                            <div className="flex">
                                <div className="lg:me-5 md:me-5 me-2 xl:min-w-[150px] lg:min-w-[140px] md:min-w-[100px] sm:min-w-[150px] min-w-[85px] flex flex-col items-center text-center border border-[#C7C7C7] lg:px-4 md:px-4 sm:px-4 px-2 lg:py-4 md:py-3 sm:py-4 py-2 sm:w-auto w-full">
                                    <MdAssignmentAdd className="h-[35px] w-[35px] mb-3 text-[#689fff]" />
                                    <p className="text-[0.8rem] sm:text-[1rem] lg:text-sm md:text-[12px] font-normal">Assessments</p>
                                </div>
                                <div className="lg:me-5 md:me-5 me-2 xl:min-w-[150px] lg:min-w-[140px] md:min-w-[100px] sm:min-w-[150px] min-w-[85px] flex flex-col items-center text-center border border-[#C7C7C7] lg:px-4 md:px-4 sm:px-4 px-2 lg:py-4 md:py-3 sm:py-4 py-2 sm:w-auto w-full">
                                    <FaRegLightbulb className="h-[35px] w-[35px] mb-3 text-[#689fff]" />
                                    <p className="text-[0.8rem] sm:text-[1rem] lg:text-sm md:text-[12px] font-normal">Quizzes</p>
                                </div>
                                <div className="lg:min-w-[140px] xl:min-w-[150px] md:min-w-[100px] sm:min-w-[150px] min-w-[85px] flex flex-col items-center text-center border border-[#C7C7C7] lg:px-4 md:px-4 sm:px-4 px-2 lg:py-4 md:py-3 sm:py-4 py-2 sm:w-auto w-full">
                                    <PiNotebookFill className="h-[35px] w-[35px] mb-3 text-[#689fff]" />
                                    <p className="text-[0.8rem] sm:text-[1rem] lg:text-sm md:text-[12px] font-normal">Tests</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Assessments;