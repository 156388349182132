"use client"
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
import { HiOutlineMail } from "react-icons/hi";
import { PiPhoneLight } from "react-icons/pi";
import RequestCallbackModal from "../common/request-callback-modal";
import Image from "next/image";
import { useEffect } from 'react';
import { Button } from '../ui/button';

const ForQueries = () => {
    const EnquerySection = {
        mainSection: "w-full bg-cover bg-center h-auto lg:h-[510px] md:h-auto sm:h-auto bg-[url('https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/queries-gradient-background.webp')]"
    }
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const AOS = require('aos');
            AOS.init();
        }
    }, []);
    useEffect(() => {
        // Load Calendly widget script
        const script = document.createElement('script');
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;
        document.body.appendChild(script);

        // Cleanup
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    // const openCalendlyPopup = () => {
    //     window.Calendly.showPopupWidget('https://calendly.com/d/3pq-jyg-j42', {
    //         // Adjust window size here
    //         width: 10,
    //         height: 1000
    //     });
    // };
    const openCalendlyPopup = () => {
        if (window.Calendly) {
            window.Calendly.initPopupWidget({
                url: 'https://calendly.com/d/3pq-jyg-j42',
                prefill: {},
                utm: {}
            });

            // Adjust popup size after widget has loaded
            const adjustPopupSize = setInterval(() => {
                const calendlyPopup: any = document.querySelector('.calendly-popup');
                if (calendlyPopup) {
                    clearInterval(adjustPopupSize);
                    calendlyPopup.style.maxWidth = '100%';
                    calendlyPopup.style.maxHeight = '900px';
                }
            }, 500);
        }
    };
    return (
        <section className={EnquerySection.mainSection}>

            <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet" />
            <div className="container" data-aos="fade-up" data-aos-duration="1000">
                <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-0">
                    <div className="py-7 lg:py-14 md:py-14 flex justify-center md:justify-start items-center order-last sm:order-first">
                        <div>
                            <div className="w-full sm:w-auto lg:text-start md:text-start text-center">
                                <p className="font-normal text-[1rem] lg:text-xl md:text-sm text-white">For Inquiries, Feedback & Assistance:</p>
                                <h2 className="font-semibold text-white text-xl lg:text-[50px] md:text-[30px] md:pb-3 lg:pb-3 pb-2 leading-tight">ExC Academy Support</h2>
                                <p className="font-semibold text-white text-[1rem] lg:text-lg md:text-sm mb-[20px] lg:mb-[55px] md:mb-[55px]">Monday - Friday   |    10 AM - 6 PM</p>
                                <div className="block lg:flex md:flex items-center mb-3 justify-between lg:text-start md:text-start text-center">
                                    <div className="bg-[#FFFFFF] block md:inline-block lg:inline-block lg:px-4 md:px-3 px-9 py-3 mr-3 mb-4 lg:mb-0 md:mb-0 w-full text-center">
                                        <p className="justify-center font-normal text-[#302A33] text-[1rem] lg:text-xl md:text-sm flex items-center whitespace-nowrap">
                                            <PiPhoneLight className="me-2" /> +91 974 110 2084
                                        </p>
                                    </div>
                                    <div className="bg-[#FFFFFF] block md:inline-block lg:inline-block lg:px-4 md:px-4 px-9 py-3 text-center w-full">
                                        <p className="justify-center font-normal text-[#302A33] text-[1rem] lg:text-xl md:text-sm flex items-center">
                                            <HiOutlineMail className="me-3" /> support@exc.academy
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center mb-3">
                                    {/* <RequestCallbackModal /> */}
                                    {/* <button onClick={openCalendlyPopup}>Schedule time with me</button> */}
                                    <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet" />
                                    <Button onClick={openCalendlyPopup} variant="primary" className="text-[16px] md:text-[16px] uppercase px-8 py-4 md:py-4 flex items-center"><PiPhoneLight className="me-3" /> Request a Callback</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="h-auto lg:h-[504px] md:h-[400px] sm:h-[300px] w-full relative flex items-center order-first sm:order-last">
                            <Image height={381} width={500} src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/queries-customer-support.webp"
                                className="sm:absolute mx-auto z-10 right-[10px]" alt="Icon"
                                blurDataURL="URL"
                                placeholder="blur"
                                fetchPriority="high"
                                loading="eager"
                                rel="preload"
                            />
                            <Image height={62} width={62} src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/queries-element-1.webp"
                                className="absolute top-[30px] sm:top-[65px] lg:top-[115px] md:top-[60px] left-[19px] lg:left-[206px] md:left-[50px]" alt="icon"
                                blurDataURL="URL"
                                placeholder="blur"
                                fetchPriority="high"
                                loading="eager"
                                rel="preload"
                            />
                            <Image height={85} width={85} src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/queries-element-2.webp"
                                className="absolute right-0 lg:right-[90px] md:right-[30px] bottom-[47px] sm:bottom-[94px] lg:bottom-[174px] md:bottom-[160px] w-auto lg:w-auto md:w-auto "
                                alt="Main Image"
                                blurDataURL="URL"
                                placeholder="blur"
                                fetchPriority="high"
                                loading="eager"
                                rel="preload"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ForQueries;