"use client"
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
import Image from "next/image";
import { useEffect } from 'react';
import Link from "../ui/link";

const BigLearning = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const AOS = require('aos');
            AOS.init();
        }
    }, []);

    return (
        <section className="lg:py-14 md:py-14 py-7">
            <div className="container">
                <div data-aos="fade-up" data-aos-duration="1000">
                    <div className="w-full bg-cover bg-center flex items-center h-auto lg:h-[400px] md:h-auto py-[20px] lg:py-0 md:py-0 bg-[url('https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/big-learning-and-career-placement-background.webp')]">
                        <div className="grid grid-cols-12 gap-0 flex items-center">
                            <div className="lg:col-span-6 md:col-span-6 col-span-12 ps-[20px] lg:ps-[90px] md:ps-[50px] md:pt-6 py-6 pe-[20px] lg:pe-0 md:pe-0 order-last md:order-first">
                                <h2 className="text-gray font-semibold text-[20px] lg:text-[32px] md:text-2xl uppercase leading-tight mb-7">Robust Learning and Career Enhancement Platform!</h2>
                                <Link href="/our-courses" variant="primary" className="uppercase py-4 lg:px-11 md:px-11 px-7 text-center lg:inline-block md:inline-block block">Discover In-Demand Courses</Link>
                            </div>
                            <div className="lg:col-span-6 md:col-span-6 col-span-12 overflow-hidden text-end order-first md:order-last">
                                <Image src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/robust-learning-1.webp"
                                    className="my-auto mx-auto" alt="Explore Courses" height={350} width={350}
                                    blurDataURL="URL"
                                    placeholder="blur"
                                    fetchPriority="high"
                                    loading="eager"
                                    rel="preload"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BigLearning;