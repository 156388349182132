"use client"
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
import { useEffect } from 'react';
import HorizontalCard from './course-card/horizontal-card';

const OurCourses = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const AOS = require('aos');
            AOS.init();
        }
    }, []);

    return (
        <section className="bg-[#F4F4F4] py-7 lg:py-14 md:py-14">
            <div className="container" data-aos="fade-up" data-aos-duration="1000">
                <div className="grid grid-cols-1">
                    <div>
                        <h2 className="text-center text-gray font-semibold text-[20px] lg:text-[35px] md:text-3xl mb-3 leading-tight">Unlock Your Potential with Our Comprehensive SAP Training!</h2>
                        <p className="text-center text-gray font-normal pt-2 pb-5 text-[1rem] lg:text-xl md:text-base">Dive into hands-on training led by industry experts, gaining practical skills crucial <br className="hidden lg:block md:block" /> for excelling in SAP-driven environments.</p>
                    </div>
                    <HorizontalCard />
                </div>
                {/* <div className="flex flex-col items-center">
                    <ViewAll />
                </div> */}
            </div>
        </section>
    );
}

export default OurCourses;